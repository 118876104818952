import React from "react"

import { Subscribe } from "unstated"
import WaitlistStore from "../stores/waitlists"
import ProductStore from "../stores/products"
import { AuthContainer } from "../stores/auth"
import AddProxyApp from "../components/AddProxyApp"

import withLayout from "../components/layout"

function Products({ isPublic = true }) {
  return (
    <Subscribe to={[WaitlistStore, ProductStore, AuthContainer]}>
      {(waitlists, productStore, authStore) => {
        const notLoggedIn =
          typeof authStore.state.user === "undefined"
            ? isPublic
            : !authStore.state.user

        return (
          <div>
            <AddProxyApp
              isPublic={notLoggedIn}
              proxyTypes={productStore.proxyTypes}
              notLaunchedProxyTypes={productStore.notLaunchedProxyTypes}
              ipRotations={productStore.ipRotations}
              subscriptionLengths={productStore.subscriptionLengths}
              fetchLocations={productStore.fetchLocations}
              {...productStore.state}
              setSelectedTier2Id={productStore.setSelectedTier2Id}
              setSelectedTier3Id={productStore.setSelectedTier3Id}
              setBillingCycle={productStore.setBillingCycle}
              setLocation={productStore.setLocation}
              setMultilocation={productStore.setMultilocation}
              setProxyLocationType={productStore.setProxyLocationType}
              multilocationProxyTypes={productStore.multilocationProxyTypes}
              addWaitlistItem={waitlists.addWaitlistItem}
              fetchWaitlist={waitlists.fetchWaitlist}
              hideWaitlistModal={waitlists.hideWaitlistModal}
              {...waitlists.state}
            />
          </div>
        )
      }}
    </Subscribe>
  )
}

export default withLayout(Products)
